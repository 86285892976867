@import url(https://fonts.googleapis.com/css2?family=Raleway);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab);
.reader{
    max-width: 50rem;
}

.navbar-brand{
    margin-left: var(--bs-navbar-brand-margin-end);
}
h1, h2, .lead{
    font-family: Raleway;
}

body{
    font-family: Roboto Slab;
}

.verse{
    border-left: solid 0.5px #aaa;
}

.explanation{
    border-top: solid 0.5px #aaa;
    border-bottom: solid 0.5px #aaa;
}

.verse.selected:hover{
    cursor:auto;
}

.verse.selected{
    border-left: solid 2px #0D6EFD;
}

.verse:hover{
    cursor: pointer;
    border-color: #0D6EFD;
}